<template>
  <button
    class="btn btn-primary"
    :disabled="showSpinner"
  >
    <BSpinner
      v-if="showSpinner"
      small
      class="mx-1"
    />

    {{ text }}
  </button>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },

  // eslint-disable-next-line vue/require-prop-types
  props: ['text', 'showSpinner'],
}
</script>
