<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link
          :to="{name: 'login'}"
          class="brand-logo"
        >
          <!-- logo -->
          <vuexy-logo />
        </b-link>

        <b-card-title class="mb-1">
          نسيت كلمة المرور ؟ 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          إدخل البريد البريد وسوف نرسل لك رابط لإعادة كلمة المرور
        </b-card-text>

        <div
          v-if="errorMessage !== null"
          class="alert alert-danger p-2"
        >
          <p>
            {{ errorMessage }}
          </p>
        </div>

        <div
          v-if="successMessage !== null"
          class="alert alert-success p-2"
        >
          <p>
            {{ successMessage }}
          </p>
        </div>

        <!-- form -->
        <validation-observer ref="forgotPasswordForm">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="resetPassword"
          >
            <!-- email -->
            <b-form-group
              label="البريد الإلكتروني"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <ButtonSpinner
              :show-spinner="formSubmitted"
              text=" إرسل الرابط"
              type="submit"
              class="btn-block"
            />
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> العودة إلي تسجيل الدخول
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm,
} from 'bootstrap-vue'
import { required, email } from '@validations'

import ButtonSpinner from '@/views/components/ButtonSpinner.vue'

import userApi from '@/axios/api/user'

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    ButtonSpinner,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',

      errorMessage: null,
      successMessage: null,
      formSubmitted: false,
      // validation
      required,
      email,
    }
  },
  methods: {
    resetPassword() {
      this.errorMessage = null
      this.successMessage = null
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          this.sendLink()
        }
      })
    },

    sendLink() {
      this.formSubmitted = true
      userApi.forgotPassword({ email: this.userEmail, source: 'client' })
        .then(() => {
          this.formSubmitted = false
          this.successMessage = 'تم إرسال الرابط إلي البريد الإلكتروني'
        }).catch(error => {
          this.formSubmitted = false
          console.error(error.response)
          if (error.response && error.response.status === 400) {
            this.errorMessage = 'هذا البريد غير معروف لدينا'
          } else {
            this.errorMessage = 'حدث خطأ أثناء إرسال الرابط'
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.brand-logo img {
  max-width: 200px !important;
}
</style>
